<template>
  <b-row>
    <b-col
      cols="6"
      class="h-screen bg-white wrapper-left-content-verified"
    >
      <b-row>
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/Komcards-logo.svg"
          alt="logo"
          width="200"
          class="pl-3 pt-2 mb-5"
        />
      </b-row>
      <b-col>
        <div class="register-title text-black text-center">
          Kartu Debit Virtual
        </div>
        <div class="text-center text-[#828282]">
          buat semua keperluan bisnis onlinemu
        </div>
        <div class="my-3">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-success-verified.svg"
            alt="Komerce"
            class="m-auto"
          >
        </div>
      </b-col>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          class="text-center text-black"
        >
          <h2 class="text-black mb-1">
            <strong>
              Selamat! Pendaftaran akun Anda berhasil.
            </strong>
          </h2>
          <p class="text-black text-[16px] mt-[24px] mb-[32px]">
            Sekarang Anda dapat masuk dan mulai menggunakan layanan Komcards
          </p>
        </b-col>
        <b-col
          cols="8"
          class="text-center"
        >
          <b-button
            variant="primary"
            tag="router-link"
            :to="{name:'auth-login'}"
            style="width: 100%;"
          >
            <strong>Masuk</strong>
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="6"
      class="h-screen wrapper-right-content-verified"
    />

    <!-- Mobile -->
    <b-col
      cols="12"
      class="h-screen bg-white wrapper-verified-mobile"
    >
      <b-row class="mb-2 pb-2">
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/Komcards-logo.svg"
          alt="logo"
          width="200"
          class="pl-3 pt-2"
        />
      </b-row>
      <b-row>
        <b-col>
          <div class="register-title text-black text-center">
            Kartu Debit Virtual
          </div>
          <div class="text-center text-[#828282]">
            buat semua keperluan bisnis onlinemu
          </div>
          <div class="my-3">
            <img
              src="https://storage.googleapis.com/komerce/core/icon-success-verified.svg"
              alt="Komerce"
              class="m-auto"
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          class="text-center"
        >
          <div class="text-black text-[24px]">
            <strong>
              Selamat! Pendaftaran akun Anda berhasil.
            </strong>
          </div>
          <p class="text-black text-[16px] mt-[24px] mb-[32px]">
            Sekarang Anda dapat masuk dan mulai menggunakan layanan Komcards
          </p>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <div class=" ml-1">
            <b-button
              block
              variant="primary"
              tag="route-link"
              :to="{name:'auth-login'}"
            >
              Masuk
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BImg,
  BCol,
  BButton,
  BContainer,
} from 'bootstrap-vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BButton,
    // BContainer,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

 <style lang="scss" scoped>
   [dir] .wrapper-right-content-verified {
     background-color: #E7E9F2C2;
         background-image: url('https://storage.googleapis.com/komerce/assets/svg/komcards-illust.svg');
     background-repeat: no-repeat;
     background-size: cover;
   }

   // Responsive
   @media only screen and (max-width: 991px) {
     [dir] .wrapper-right-content-verified {
       display: none;
     }
     [dir] .wrapper-left-content-verified {
       display: none;
     }
   }
   @media only screen and (min-width: 992px) {
     [dir] .wrapper-verified-mobile {
       display: none;
     }
   }
 @import '@core/scss/vue/pages/page-misc.scss';
@import 'RegisterKomcard.scss';
 </style>
